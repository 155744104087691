<div class="modal-wrapper schedule-modal" cf-loader-container position="bottom">
  <div cf-modal-header [hideCloseButton]="isNewUser"></div>
  <div class="modal-body">
    <h3>{{ 'schedules.connect_your_calendar' | translate }}</h3>
    <cf-text color="grey-70" weight="400">
      {{ 'schedules.connect_your_calendar_text_1' | translate }}
    </cf-text>
    <cf-text color="grey-70" weight="500" class="d-block">
      {{ 'schedules.connect_your_calendar_text_2' | translate }}
    </cf-text>
    <div class="mt-4">
      <cf-google-button
        [isCalendar]="true"
        (callback)="connectGoogleCalendar($event)"
      >
        Google Calendar
      </cf-google-button>

      <cf-microsoft-button
        [isCalendar]="true"
        class="mt-2 w-100"
        redirectTarget="_blank"
      >
        Microsoft Calendar
      </cf-microsoft-button>
    </div>
    <div class="mt-4 text-center" *ngIf="isNewUser">
      <a cf-link (click)="skipThisStep($event)">
        {{ 'schedules.skip_this_step' | translate }}
      </a>
    </div>
  </div>
</div>
