import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { AccountService, CalendarStoreService, ConnectionService } from '@cf/temp/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
type CodeResponse = google.accounts.oauth2.CodeResponse;

@Component({
  selector: 'cf-modal-connect-calendar',
  templateUrl: './modal-connect-calendar.component.html',
  styleUrls: ['./modal-connect-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ModalConnectCalendarComponent implements OnInit {
  @Input() isNewUser = true;

  constructor(
    private readonly accountService: AccountService,
    private readonly activeModal: NgbActiveModal,
    private readonly connectionService: ConnectionService,
    private readonly calendarStoreService: CalendarStoreService,
  ) {}

  ngOnInit() {
    if (this.isNewUser) {
      this.accountService.updatePartialAccount({ show_connect_calendar: false }).subscribe();
    } else {
      this.connectionService.broadcastChannel.onmessage = () => {
        this.updateShowCalendarState();
      };
    }
  }

  public connectGoogleCalendar(codeResponse: CodeResponse): void {
    if (codeResponse.code) {
      this.accountService
        .connectCalendarFromGoogle(
          {
            code: codeResponse.code,
          },
          false,
        )
        .subscribe(() => {
          this.updateShowCalendarState();
        });
    }
  }

  public skipThisStep($event: Event): void {
    $event.preventDefault();
    this.updateShowCalendarState();
  }

  private updateShowCalendarState(): void {
    if (!this.isNewUser) {
      this.accountService.getAccountProfile();
      this.calendarStoreService.getCalendars();

      this.activeModal.close();
      return;
    }
    this.accountService
      .updatePartialAccount({ show_connect_calendar: false })
      .subscribe(() => this.activeModal.close());
  }
}
